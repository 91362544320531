<template>
    <main class>
      <v-app class>
        <div>
          <v-alert
            v-model="alert"
            border="left"
            dark
            dismissible
            class="alert"
            :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}"
          >
            {{alertMessage}}
          </v-alert>
        </div>
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <v-container>
              <div class="mb-8 mt-8 text-center">
                <h1>Edit Personnel Data</h1>
              </div>
              <div class="w-100">
                <v-expansion-panels multiple class="mb-5">
                  <v-expansion-panel>
                    <!-- Personnel  data form section  -->
                    <v-expansion-panel-header>Personnel Data</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="personnelForm">
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <TextField v-model="formData.FirstName" :label="'Firstname'" />
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <TextField v-model="formData.MiddleName" :validate="false" :label="'Middlename'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.LastName" :label="'Lastname'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.Email" :type="'email'" :label="'EMAIL'" />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.Mobile"
                              :type="'number'"
                              :label="'Phonenumber'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <!-- <TextField
                              v-model="formData.DateOfBirth"
                              :type="'date'"
                              :label="'Dath of Birth'"
                              @blur="DateOfBirthSelected"
                            /> -->
                            <v-text-field
                              v-model="formData.DateOfBirth"
                              outlined
                              label="Date of Birth"
                              @blur="DateOfBirthSelected"
                              dense
                              type="date"
                            />
                          </v-col>                         
                          <v-col cols="12" sm="6">
                            <TextField v-model="formData.AnnualSalaryAmount" :disable="true" :label="'Annual Salary'" />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Personnel  data form section end  -->

                  <!-- Employee data form section   -->
                  <v-expansion-panel>
                    <v-expansion-panel-header>Employment Data</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-form ref="employmentForm">
                        <v-row dense>
                          <v-col cols="12" sm="6">
                            <v-select v-model="formData.ContractEmployer" :items="contractEmployer" item-value="id" item-text="name" label="Contractor Employer" return-object outlined dense/>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select v-model="formData.WorkArea" :items="workAreas" item-value="id" item-text="name" label="Work Area" return-object outlined dense/>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select @change="unionMemberChange" v-model="formData.UnionMembership" :items="unionMembers" item-value="id" item-text="name" label="Union Member" return-object outlined dense/>
                          </v-col>
                          <v-col cols="12" sm="6">
                                                      <v-select v-model="formData.JobTitle" :items="jobTitles" item-value="id" item-text="name" label="Job Title" return-object outlined dense/>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.JobTitle.salaryAmount"
                              :disable="true"
                              :label="'Salary Amount'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.ResumptionDate"
                              :type="'date'"
                              :label="'Date Of Employment'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <TextField
                              v-model="formData.RetirementDate"
                              :disable="true"
                              :label="'Date Of Retirement'"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-autocomplete
                              :items="chargeCode"
                              v-model="formData.chargeCode"
                              outlined
                              dense
                              item-text="costCenter"
                              label="Charge Code"
                              return-object
                              item-value="costCenter"
                            ></v-autocomplete>

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Supervisor"
                              v-model="supervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="supervisorSearch = true"
                              @click.prevent="supervisorSearch = true"
                            />

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Supervisor"
                              v-model="altSupervisorName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSupervisorSearch = true"
                              @click.prevent="altSupervisorSearch = true"
                            />

                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Superintendent"
                              v-model="SuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="superintendentSearch = true"
                              @click.prevent="superintendentSearch = true"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              type="text"
                              label="Alternate Superintendent"
                              v-model="altSuperintendentName"
                              :append-icon="'mdi-magnify'"
                              @click:append="altSuperintendentSearch = true"
                              @click.prevent="altSuperintendentSearch = true"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              dense
                              readonly
                              outlined
                              required
                              label="GM"
                              type="text"
                              v-model="GMname"
                              :append-icon="'mdi-magnify'"
                              @click:append="gmSearch = true"
                              @click.prevent="gmSearch = true"
                            />
                          </v-col>
                          <v-col cols="12" sm="6">

                            <v-select v-model="formData.Department" :items="departments" item-value="id" item-text="name" label="Department" return-object outlined dense/>
                          </v-col>
                          <v-col cols="12" sm="6">
                             <v-select v-model="formData.Location" :items="locations" item-value="id" item-text="name" label="Location" return-object outlined dense/>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select v-model="formData.Rotation" :items="rotations" item-value="id" item-text="name" label="Rotation" return-object outlined dense/>
                          </v-col>
                           <v-col cols="12" sm="6">
                             <!-- <v-select @change="unionMemberChange" v-model="formData.OrgIdentification" :items="organisationIds" item-value="id" item-text="orgId" label="Org ID" return-object outlined dense/> -->
                            <v-autocomplete
                              :items="orgId"
                              v-model="formData.OrgIdentification"
                              outlined
                              dense
                              :rules="[val => (val || '').length > 0 || 'This field is required']"
                              item-text="divisionName"
                              label="Division"
                              return-object
                              item-value="id"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <!-- Employee data form section  end  -->
                </v-expansion-panels>

                <v-row>
                  <v-col cols="12" class="d-flex justify-end">
                    <div class="btn-group">
                      <Button
                      :label="'Save'"
                      :btnType="'Submit'"
                      @onClick="dialog = true"
                      :isLoading="isLoading"
                      class="mr-4"
                    />

                    <Button :label="'Cancel'" :btnType="'Cancel'" @onClick="onReset" />
                    </div>
                  </v-col>
                </v-row>
              </div>
              <PersonnelSearch :dialog="supervisorSearch" @selectPer="approver ($event,'SuperVisor')"  @close="supervisorSearch = false" :type="'employee'" :title="'Supervisor'"/>

              <PersonnelSearch :dialog="altSupervisorSearch" @selectPer="approver ($event,'AltSuperVisor')"  @close="altSupervisorSearch = false" :type="'employee'" :title="'Alternate Supervisor'"/>

               <PersonnelSearch :dialog="superintendentSearch" @selectPer="approver ($event,'Superintendent')"  @close="superintendentSearch = false" :type="'employee'" :title="'Superintendent'"/>

              <PersonnelSearch :dialog="altSuperintendentSearch" @selectPer="approver ($event,'AltSuperintendent')"  @close="altSuperintendentSearch = false" :type="'employee'" :title="'Alternate Superintendent'"/>

              <PersonnelSearch :dialog="gmSearch" @selectPer="approver ($event,'GM')"  @close="gmSearch = false" :type="'employee'" :title="'GM'"/>

              <ConfirmationDialog :title="'Are you sure you want to submit?'" :isLoading="isSavingUser" :dialog="dialog" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
            </v-container>
          </div>
        </div>
      </v-app>
    </main>
</template>
<script>
import moment from 'moment'
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import PersonnelSearch from '@/components/ui/PersonnelSearchModal.vue'
import {
  employeeAuditTrailService,
  departmentService,
  workAreaService,
  jobTitleService,
  locationService,
  contractEmployerService,
  nmaIntegrationService,
  employeeService,
  rotationService,
  unionMemberService
} from '@/services'

export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog,
    PersonnelSearch
  },
  inject: ['eventBus'],
  provide () {
    return {
      eventBus: this.eventBus
    }
  },
  data () {
    return {
      count: 0,
      search: '',
      alertMessage: '',
      alert: false,
      alertType: '',
      dialog: false,
      isLoading: false,
      supervisorSearch: false,
      altSupervisorSearch: false,
      superintendentSearch: false,
      altSuperintendentSearch: false,
      gmSearch: false,
      isEditLoading: false,
      supervisorLoading: false,
      superitendantLoading: false,
      gmLoading: false,
      valid: true,
      formData: {
        id: '',       
        FirstName: '',
        prNumber: '',
        MiddleName: '',
        LastName: '',
        DateOfBirth: '',
        ResumptionDate: '',
        RetirementDate: '',
        Email: '',
        AnnualSalaryAmount: '',
        Mobile: '',
        ContractEmployer: '',
        chargeCode: '',
        Location: '',
        Department: '',
        JobTitle: '',
        Rotation: '',
        WorkArea: '',
        approvers: [],
        active: false,
        deleted: false,
        action: 'Edit',
        status: 0,
        addedBy: 'TQVD',
        dateAdded: '2020-08-23T00:00:00',
        lastModifiedDate: null,
        modifiedBy: 'admin',
        UnionMemberId: '',
        orgId: '',
        OrgIdentification: '',
        UnionMembership: ''
      },
      SuperintendentName: '',
      supervisorName: '',
      GMname: '',
      salaryAmount: 0,
      contractEmployer: [],
      supervisor: [],
      superitendant: [],
      GMS: [],
      isSavingUser: false,
      headers: [
        {
          text: 'name',
          value: 'name'
        },
        {
          text: 'id',
          value: 'id'
        },
        {
          text: 'email',
          value: 'emailAddress'
        }
      ],
      unionMembers: [],
      altSupervisorName: '',
      altSuperintendentName: '',
      organisationIds: []
    }
  },
  computed: {
    departments () {
      return this.getStorageItem('department')
    },
    jobTitles () {
      return this.getStorageItem('jobTitle')
    },
    workAreas () {
      return this.getStorageItem('workArea')
    },
    locations () {
      return this.getStorageItem('location')
    },
    rotations () {
      return this.getStorageItem('rotation')
    },
    orgId () {
      return this.getStorageItem('organisation')
    },
    chargeCode () {
      return this.getStorageItem('chargeCode')
    }
  },
  methods: {
    getStorageItem (val) {
      const data = localStorage.getItem(val)
      return JSON.parse(data)
    },
    unionMemberChange (value) {
      if (value !== null) {
        const self = this
        jobTitleService.getJobTitleByUnion(value.id).then(result => {
          self.jobTitles = result.data
        })
      }
    },
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    getSalaryAmount (item) {
      const jobtitle = this.jobTitles.filter(title => title.id === item)
      this.salaryAmount = this.formatMoney(jobtitle[0].salaryAmount)
    },
    openSupervisorDialog () {
      this.$refs.Supervisordialog.toggleModal()
    },
    openAltSupervisorDialog () {
      this.$refs.AltSupervisordialog.toggleModal()
    },
    formatMoney (amount, decimalCount = 2, decimal = '.', thousands = ',') {
      try {
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount

        const negativeSign = amount < 0 ? '-' : ''

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString()
        let j = (i.length > 3) ? i.length % 3 : 0

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : '')
      } catch (e) {}
    },
    openSuperintendentDialog () {
      this.$refs.Superintendentdialog.toggleModal()
    },
    openAltSuperintendentDialog () {
      this.$refs.AltSuperintendentdialog.toggleModal()
    },
    openGMDialog () {
      this.$refs.GMdialog.toggleModal()
    },
    salaryAmountValue () {

    },
    approver (item, title) {
      let approverTitle = title
      let alternate = false
      if (title === 'SuperVisor') {
        this.search = ''
        this.supervisor = []
        this.supervisorName = item.emailAddress
        approverTitle = 'SuperVisor'
      } else if (title === 'Superintendent') {
        this.search = ''
        this.superitendant = []
        approverTitle = 'Superintendent'
        this.SuperintendentName = item.emailAddress
      } else if (title === 'GM') {
        this.search = ''
        this.GMS = []
        this.GMname = item.emailAddress
      } else if (title === 'AltSuperVisor') {
        this.search = ''
        this.supervisor = []
        this.altSupervisorName = item.emailAddress
        approverTitle = 'SuperVisor'
        alternate = true
      } else if (title === 'AltSuperintendent') {
        this.search = ''
        this.supervisor = []
        this.altSuperintendentName = item.emailAddress
        approverTitle = 'Superintendent'
        alternate = true
      }

      const newApprovers = []
      if (this.formData.approvers.length > 0) {
        this.formData.approvers.forEach(item => {
          if (!(item.approverTitle === approverTitle && item.isAlternate === alternate)) {
            newApprovers.push(item)
          }
        })
      }

      newApprovers.push({        
        approverEmail: item.emailAddress,
        approverTitle: approverTitle,
        isAlternate: alternate
      })

      // const approversString = JSON.stringify(newApprovers)
      // console.log(approversString)
      // const approverObject = JSON.parse(approversString)
      // console.log(approverObject)
      this.formData.approvers = []
      this.formData.approvers = newApprovers
    },
    superVisorSearchInput () {
      if (this.search === '') {
        this.supervisor = []
        return
      }
      this.supervisorLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.supervisor = result.data
      }).catch().finally(() => {
        this.supervisorLoading = false
      })
    },
    SuperintendentInput () {
      if (this.search === '') {
        this.superitendant = []
        return
      }
      this.superitendantLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.superitendant = result.data
      }).catch(() => {
        this.showAlertMessage('Unable to load, Try again', 'error')
      }).finally(() => {
        this.superitendantLoading = false
      })
    },
    GMInput () {
      if (this.search === '') {
        this.GMS = []
        return
      }
      this.gmLoading = true
      employeeService.searchEmployees(this.search).then(result => {
        this.GMS = result.data
      }).catch(() => {
        this.showAlertMessage('Unable to load, Try again', 'error')
      }).finally(() => {
        this.gmLoading = false
      })
    },
    onSave () {
      this.isLoading = true
      console.log(this.formData)
      const data = {
        EmployeeId: this.formData.id,        
        FirstName: this.formData.FirstName,
        MiddleName: this.formData.MiddleName,
        LastName: this.formData.LastName,
        DateOfBirth: this.formData.DateOfBirth,
        ResumptionDate: this.formData.ResumptionDate,
        RetirementDate: this.formData.RetirementDate,
        Email: this.formData.Email,
        AnnualSalaryAmount: this.formData.AnnualSalaryAmount,
        Mobile: this.formData.Mobile,
        ContractEmployerId: this.formData.ContractEmployer.id,
        chargeCode: this.formData.chargeCode.costCenter,
        LocationId: this.formData.Location.id,
        DepartmentId: this.formData.Department.id,
        JobTitleId: this.formData.JobTitle.id,
        RotationId: this.formData.Rotation.id,
        WorkAreaId: this.formData.WorkArea.id,
        approvers: this.formData.approvers,
        prNumber: this.formData.prNumber,
        active: false,
        deleted: false,
        action: 'Edit',
        status: 0,
        addedBy: 'TQVD',
        dateAdded: '2020-08-23T00:00:00',
        lastModifiedDate: null,
        modifiedBy: 'admin',
        UnionMemberId: this.formData.UnionMembership.id,
        orgId: this.formData.OrgIdentification.id
      }

      employeeAuditTrailService.updateEmployee(data).then(result => {
        this.showAlertMessage('Personnel Data updated successful', 'success')
        this.isLoading = false
      }).catch(() => {
        this.showAlertMessage('Personnel Data update failed', 'error')
        this.isLoading = false
      }).finally(() => {
        this.isLoading = false
        this.dialog = false
      })
    },
    closeSuperintendentDialog () {
      this.$refs.Superintendentdialog.closeModal()
    },
    closeGMDialog () {
      this.$refs.GMdialog.closeModal()
    },
    closeSupervisorDialog () {
      this.$refs.Supervisordialog.closeModal()
    },
    closeAltSuperintendentDialog () {
      this.$refs.AltSuperintendentdialog.closeModal()
    },
    closeAltSupervisorDialog () {
      this.$refs.AltSupervisordialog.closeModal()
    },
    onReset () {
      //this.$refs.employmentForm.reset()
      //this.$refs.personnelForm.reset()
      //this.SuperintendentName = ''
      //this.supervisorName = ''
      //this.altSupervisorName = ''
      //this.altSuperintendentName = ''
      //this.GMName = ''
      //this.superitendant = []
      //this.supervisor = []
      //this.GMS = []
      this.$router.push(`/personnel-data/list`)
    },
    edit (item) {
      this.unionMemberChange(item.unionMemberId)
      this.formData.id = item.id
      this.formData.FirstName = item.firstName
      this.formData.Email = item.email
      this.formData.LastName = item.lastName
      this.formData.MiddleName = item.middleName
      this.formData.Mobile = item.mobile     
      this.formData.DateOfBirth = moment(item.dateOfBirth).format('YYYY-MM-DD')
      this.formData.ResumptionDate = moment(item.resumptionDate).format('YYYY-MM-DD')
      this.formData.RetirementDate = moment(item.retirementDate).format('YYYY-MM-DD')
      this.formData.Location = item.location
      this.formData.Rotation = item.rotation
      this.formData.Department = item.department
      this.formData.ContractEmployer = item.contractEmployer
      this.formData.WorkArea = item.workArea
      this.formData.JobTitle = item.jobTitle
      this.formData.prNumber = item.prnumber
      this.formData.AnnualSalaryAmount = item.annualSalaryAmount
      this.formData.UnionMembership = item.unionMembership
      this.formData.OrgIdentification = this.orgId.filter(org => org.id === item.orgId)[0]
      this.formData.chargeCode = { 'costCenter': item.chargeCode, 'costCenterDesc': item.chargeCode }
      const superVisor = item.approvers.find(app => app.approverTitle === 'SuperVisor' && app.isAlternate === false)
      const superitendant = item.approvers.find(app => app.approverTitle === 'Superintendent' && app.isAlternate === false)
      const gm = item.approvers.find(app => app.approverTitle === 'GM' && app.isAlternate === false)
      const altSupervisor = item.approvers.find(app => app.approverTitle === 'SuperVisor' && app.isAlternate === true)
      const altSuperintendent = item.approvers.find(app => app.approverTitle === 'Superintendent' && app.isAlternate === true)
      this.supervisorName = superVisor ? superVisor.approverEmail : ''
      this.SuperintendentName = superitendant ? superitendant.approverEmail : ''
      this.GMname = gm ? gm.approverEmail : ''
      this.altSupervisorName = altSupervisor ? altSupervisor.approverEmail : ''
      this.altSuperintendentName = altSuperintendent ? altSuperintendent.approverEmail : ''
      this.formData.approvers = item.approvers
    },
    DateOfBirthSelected () {
      const date = moment(this.formData.DateOfBirth).format('DD-MM-YYYY')
      let newDate = moment(date, 'DD-MM-YYYY').add(60, 'years')
      console.log('🚀 ~ file: editPersonnel.vue ~ line 733 ~ DateOfBirthSelected ~ newDate', newDate)
      this.formData.RetirementDate = newDate.format('MM-DD-YYYY')
    }
  },
  mounted () {
    const self = this

    unionMemberService.getAllUnionMembers().then(result => {
      self.unionMembers = result.data
      self.count = self.count + 1
    })

    departmentService.getAllDepartments().then((result) => {
      self.departments = result.data.items
      self.count = self.count + 1
    })

    workAreaService.getAllWorkArea().then((result) => {
      self.workAreas = result.data.items
      self.count = self.count + 1
    })

    contractEmployerService.getAllContractEmployers().then((result) => {
      self.contractEmployer = result.data.items
      self.count = self.count + 1
    })

    nmaIntegrationService
      .getAllChargeCodes()
      .then((result) => {
        self.chargeCode = result.data
        self.count = self.count + 1
      })
      .catch(() => {})
    nmaIntegrationService
      .getAllOrgId()
      .then((result) => {
        this.orgId = result.data
        self.count = self.count + 1
      })
      .catch((error) => {
        console.log('errorChargecode', error)
      })

    jobTitleService
      .getAllJobTitles()
      .then((result) => {
        self.jobTitles = result.data.items
        self.count = self.count + 1
      })
      .catch(() => {})

    locationService
      .getLocations()
      .then((result) => {
        self.locations = result.data.items
        self.count = self.count + 1
      })
      .catch(() => {})

    rotationService.getAllRotation().then((result) => {
      self.rotations = result.data.items
      self.count = self.count + 1
    }).catch(() => {})

    employeeService.getOrganisationIds().then(result => {
      console.log('orgs', result)
      self.organisationIds = result.data
      self.count = self.count + 1
    })
    employeeService.getEmployeesById(self.$route.params.id).then(result => {
      this.edit(result.data)
      self.count = self.count + 1
    }).catch(error => {
      this.showAlertMessage(error.message, 'error')
    })
  }
}
</script>
<style scoped>
.search-btn {
  background-color: #e6e3e3;
  height: 40px;
  border-radius: 3px !important;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.btn-style{
  color: rgba(0, 0, 0, 0.6);
  text-align: left;
  margin-bottom: 12px !important;
}
.text-style{
  text-transform: capitalize;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
